import React from 'react'
import Todo from './components/todoreact/todo'

const App = () => {
  return (
    <>
      <Todo />
    </>
  )
}

export default App
